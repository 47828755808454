var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "LargeModal",
        {
          attrs: {
            background: "#ffffff",
            size: "large",
            display: _vm.display,
            padding: _vm.kycStep === "success" ? "p-0" : "px-6 pt-6 pb-4",
          },
          on: {
            close: function ($event) {
              _vm.showReassurePrompt = true
            },
          },
        },
        [
          _c("div", { staticClass: "lg:w-3/5 mx-auto" }, [
            _c(
              "div",
              [
                _c(
                  "transition",
                  {
                    key: _vm.kycStep,
                    attrs: { name: "slideX", mode: "out-in" },
                  },
                  [
                    _c(_vm.kycStep, {
                      tag: "component",
                      on: { success: _vm.handleSuccess },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showReassurePrompt },
          on: {
            close: function ($event) {
              _vm.showReassurePrompt = false
            },
          },
        },
        [
          _c(
            "h5",
            {
              staticClass:
                "text-lg text-center md:text-xl primary-text font-bold",
            },
            [_vm._v("Are you sure you want to skip this step?")]
          ),
          _c("p", { staticClass: "mt-8 text-grey text-center" }, [
            _vm._v(
              "Your account is not verified and you are not qualified to apply for shopping credit."
            ),
          ]),
          _c("Button", {
            staticClass: "mt-12",
            attrs: { width: "w-full", text: "No, continue to verify account" },
            on: {
              click: function ($event) {
                _vm.showReassurePrompt = false
              },
            },
          }),
          _c(
            "p",
            {
              staticClass:
                "text-center mt-4 font-semibold text-sm cursor-pointer",
              on: {
                click: function ($event) {
                  _vm.handleSuccess()
                  _vm.showReassurePrompt = false
                },
              },
            },
            [_vm._v(" Yes, skip this step ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }