<template>
  <div>
    <LargeModal
      background="#ffffff"
      size="large"
      :display="display"
      :padding="kycStep === 'success' ? 'p-0' : 'px-6 pt-6 pb-4'"
      @close="showReassurePrompt = true"
    >
      <div class="lg:w-3/5 mx-auto">
        <div>
          <transition name="slideX" mode="out-in" :key="kycStep">
            <component :is="kycStep" @success="handleSuccess" />
            <!-- <PersonalDetails/> -->
          </transition>
        </div>
      </div>
      <!-- <template v-slot:title>
        <div class="flex items-center justify-around mx-auto lg:w-3/5 w-full">
          <div class="flex items-center">
            <div
              class="md:h-8 md:w-8 h-6 w-6 rounded-full grid place-content-center mr-2"
              :class="[kycStep === 'Bvn' ? 'bg-brandPurple' : 'bg-lightGrey-1']"
            >
              <p class="text-xs md:text-sm" :class="[kycStep === 'Bvn' ? 'text-white' : '']">1</p>
            </div>
            <p class="text-xs md:text-sm" :class="[kycStep === 'Bvn' ? 'text-brandPurple' : 'text-grey']">Enter BVN</p>
          </div>
          <div class="line"></div>
          <div class="flex items-center">
            <div
              class="md:h-8 md:w-8 h-6 w-6 rounded-full grid place-content-center mr-2"
              :class="[kycStep === 'Phone' || kycStep === 'Camera' ? 'bg-brandPurple' : 'bg-lightGrey-1']"
            >
              <p class="text-xs md:text-sm" :class="[kycStep === 'Phone' || kycStep === 'Camera' ? 'text-white' : '']">2</p>
            </div>
            <p class="text-xs md:text-sm" :class="[kycStep === 'Phone' || kycStep === 'Camera' ? 'text-brandPurple' : 'text-grey']">Verification</p>
          </div>
          <div class="line"></div>
          <div class="flex items-center">
            <div
              class="md:h-8 md:w-8 h-6 w-6 rounded-full grid place-content-center mr-2"
              :class="[kycStep === 'Address' ? 'bg-brandPurple' : 'bg-lightGrey-1']"
            >
              <p class="text-xs md:text-sm" :class="[kycStep === 'Address' ? 'text-white' : '']">3</p>
            </div>
            <p class="text-grey text-xs md:text-sm" :class="[kycStep === 'Address' ? 'text-brandPurple' : 'text-grey']">Address</p>
          </div>
        </div>
      </template> -->
    </LargeModal>
    <!-- to be sure modal -->
    <Modal :display="showReassurePrompt" @close="showReassurePrompt = false">
      <h5 class="text-lg text-center md:text-xl primary-text font-bold">Are you sure you want to skip this step?</h5>
      <p class="mt-8 text-grey text-center">Your account is not verified and you are not qualified to apply for shopping credit.</p>
      <Button class="mt-12" width="w-full" text="No, continue to verify account" @click="showReassurePrompt = false" />
      <p
        class="text-center mt-4 font-semibold text-sm cursor-pointer"
        @click="
          handleSuccess();
          showReassurePrompt = false;
        "
      >
        Yes, skip this step
      </p>
    </Modal>
  </div>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import { Modal, LargeModal } from "@/UI/Modals";
  import { Button } from "@/UI/Button";
  export default {
    props: {
      display: {
        type: Boolean,
        default: false,
        required: true,
      },
    },
    data: () => ({
      showSuccess: false,
      showReassurePrompt: false,
    }),
    components: {
      Bvn: () => import("./Bvn"),
      Camera: () => import("./Camera/Camera.vue"),
      Phone: () => import("./Phone/Phone.vue"),
      Address: () => import("./Address/Address.vue"),
      Modal,
      LargeModal,
      Button,
      Success: () => import("../Success/Success.vue"),
      // PersonalDetails: () => import("./PersonalDetails/PersonalDetails.vue"),
    },
    computed: {
      ...mapState({
        kycStep: (state) => state.dashboard.kycSteps.activeTab,
      }),
    },
    methods: {
      ...mapActions("dashboard", ["showKycStep"]),
      ...mapActions("dashboard", ["handleShowReminder", "getDashboardData", 'handleShowPersonalDetails']),
      ...mapActions("loading", ["setLoading"]),
      handleSuccess() {
        this.showKycStep(false);
        this.setLoading({
          display: true,
          description: 'Please wait...'
        });
        this.getDashboardData()
          .then(() => {
            this.setLoading(false);
            this.handleShowReminder(true);
          })
          .catch(() => {
            this.setLoading(false);
          });
      },
    },
  };
</script>
<style scoped>
  @import "~@/assets/styles/animation.css";
  .line {
    height: 1rem;
    border: 1px solid #e0dfe3;
    background: #e0dfe3;
  }
</style>
